/**
This entire file is dedicated to how we want to deviate from Angular Material's natural styling choices
 */

// region Cards
.mat-mdc-card {
  &:not([class*="mat-elevation-z"]) {
    box-shadow: none !important;
  }
}
// endregion

// region Datepickers
.mat-date-range-input {
  display: flex !important;
  font-size: 1rem !important;
}
// endregion

// region Input
// Overrides the light-grey backgrounds states of input fields
// May consider removing to improve accessibility
.mdc-text-field--filled:not(.mdc-text-field--disabled),
.mat-mdc-form-field-focus-overlay {
  background-color: transparent !important;
}

// mat-select options
.mat-mdc-option .mdc-list-item__primary-text {
  @apply py-8;
  @apply text-gray-dark text-md font-normal #{!important};
}
.mat-mdc-option:focus.mdc-list-item,
.mat-mdc-option.mat-mdc-option-active.mdc-list-item,
.mat-mdc-option:hover:not(.mdc-list-item--disabled) {
  background: rgb(243, 251, 251) !important;
}
// endregion

// region Snack-Bars
.mat-mdc-snack-bar-container {
  padding: 0 !important;
}

// Since we ONLY need to access these tailwind colors (not exposed without customization) here,
//  hard-coding. Please use the colors of the corresponding tailwind classes found in the
//  snack bar message component's definitions.
.snack-bar {
  &--info,
  &--default {
    --mdc-snackbar-container-color: #696969 !important; // gray-dark
  }
  &--success {
    --mdc-snackbar-container-color: #00853f !important; // green-dark
  }
  &--error {
    --mdc-snackbar-container-color: #9e1b32 !important; // red
  }
  &--warning {
    --mdc-snackbar-container-color: #fdb913 !important; // yellow-dark
  }
}
// endregion

// region Tables
table.mat-mdc-table {
  display: block;
  background-color: transparent !important;
}
.mat-mdc-paginator {
  background-color: transparent !important;
}
.account-pagination {
  .mat-mdc-paginator-container {
    justify-content: flex-start !important;
  }
  .mat-mdc-table tbody td:first-child {
    border-right: none !important;
  }
}
th.mat-mdc-header-cell {
  font-size: 0.9375rem;
  line-height: 1.6;
  letter-spacing: 0.4px;
  transition: font 0.15s ease-in-out;
  font-weight: bold;
  @apply text-teal-darker;
  &.mat-mdc-table-sticky {
    @apply bg-white dark:bg-dm-bg;
  }
}
tr.mat-mdc-header-row {
  height: 51px;
  column-gap: 1.5em;
}
tr.mat-mdc-row {
  column-gap: 1.5em;
}
td.mat-mdc-cell {
  &.mat-mdc-table-sticky {
    @apply bg-white dark:bg-dm-bg;
  }
}
@media only screen and (min-width: 692px) {
  th.mat-mdc-header-cell {
    font-size: 1rem;
  }
}

.mat-mdc-form-field-icon-suffix {
  padding: 0 0 0 0px !important;
}
// endregion
.curtailment-chart {
  mat-form-field {
    .mat-mdc-form-field-subscript-wrapper {
      display: none;
    }
    .mat-mdc-text-field-wrapper {
      height: 30px;
    }
    .mat-mdc-form-field-infix {
      padding-top: 13px !important;
      padding-bottom: 13px !important;
    }
    .mat-mdc-form-field-infix {
      min-height: 30px !important;
    }
  }
}
