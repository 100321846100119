@import "./loader.scss";
@import "./overrides/imports.scss";
@import "./fonts.scss";
@import "./light-mode.scss";
@import "./dark-mode.scss";
@import "./components/imports.scss";
@import "./utils/imports.scss";
@import "./missing-tailwind.scss";

strong {
  font-weight: bold;
}
