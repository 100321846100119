@font-face {
  font-family: "NewsGothicBT-Roman";
  src: url("^assets/fonts/newsgothicbt-roman.woff2"),
    url("^assets/fonts/newsgothicbt-roman.woff");
  font-weight: normal;
}

@font-face {
  font-family: "NewsGothicBT-Roman";
  src: url("^assets/fonts/newsgothicbt-bold.woff2"),
    url("^assets/fonts/newsgothicbt-bold.woff");
  font-weight: bold;
}

* {
  font-family: "NewsGothicBT-Roman", Arial, sans-serif;
}
