/* You can add global styles to this file, and also import other style files */
@use "@angular/material" as mat;

@tailwind base;
@tailwind components;
@tailwind utilities;

@import "@angular/cdk/overlay-prebuilt.css";
@import "./app/shared/assets/styles/main.scss";

// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import "@angular/material/theming";
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.all-component-typographies();
@include mat.core();

:root {
  font-size: 100%;
  --mobile-min-height: calc(
    100vh - (53px + 67px)
  ); /* will cover the 100% of viewport */
  --desktop-medium-min-height: calc(
    100vh - (95px + 67px)
  ); /* will cover the 100% of viewport */
  --desktop-large-min-height: calc(
    100vh - (95px + 91px)
  ); /* will cover the 100% of viewport */
}

// Fixes tw + Angular Material issue with putting vertical lines after mat-labels, when appearance is outline
.mdc-notched-outline {
  &__notch,
  &__trailing {
    @apply border-none;
  }
}

.main-container {
  min-height: var(--mobile-min-height);
}
@media only screen and (min-width: 692px) {
  .main-container {
    min-height: var(--desktop-medium-min-height);
  }
}
@media only screen and (min-width: 830px) {
  .main-container {
    min-height: var(--desktop-large-min-height);
  }
}

section {
  @apply rounded-md;
}

.bg-black {
  --tw-bg-opacity: 0.32 !important;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}
