@use "@angular/material" as mat;
@import "@angular/material/theming";
@import "./colors";

$light-palette: (
  50: #e1f5f9,
  100: #b4e5f1,
  200: #85d3e8,
  300: #5ac2e0,
  400: #3cb5dc,
  500: $color-teal-dark-x,
  600: $color-png-blue-dark,
  700: #0c88b8,
  800: #0b77a5,
  900: $color-blue,
  A100: color(blue, base),
  A200: $color-teal-dark-x,
  A300: color(blue, base),
  A400: color(blue, base),
  contrast: (
    50: $black-87-opacity,
    100: $black-87-opacity,
    200: $black-87-opacity,
    300: $black-87-opacity,
    400: $black-87-opacity,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: $black-87-opacity,
    A200: $black-87-opacity,
    A400: $black-87-opacity,
    A700: white,
  ),
);

$light-theme-primary: mat.define-palette($light-palette);
$light-theme-accent: mat.define-palette($light-palette, A200, A100, A400);
$light-theme: mat.define-light-theme(
  (
    color: (
      primary: $light-theme-primary,
      accent: $light-theme-accent,
    ),
  )
);

section:not(#dashboard-header),
.mat-mdc-row,
.mat-mdc-header-cell,
.mat-mdc-table {
  @apply bg-white;
}

// Utility class for elements intended for fullscreen by applying Element.requestFullscreen
// Usage: [ngClass]="themeService.themeBackgroundClass"
.light-background::backdrop {
  @apply bg-white;
}

a:not(.de-site-header-title__link, .nav-group__button) {
  @apply text-teal-darker;
}

@include mat.all-component-themes($light-theme);
