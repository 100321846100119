$background-color_1: #d7d9da;
$background-color_2: #ffffff;
$background-color_3: #26bcd7;

app-toggle {
  .duke-toggle {
    display: flex;
    column-gap: 7px;
    align-items: center;
    justify-content: center;
    .container {
      background-color: $background-color_1;
      height: 30px;
      width: 50px;
      border-radius: 22px;
      position: relative;
      cursor: pointer;
      transition: background-color 100ms ease-in-out;
      .knob {
        position: absolute;
        top: 5px;
        left: 5px;
        background-color: $background-color_2;
        height: 20px;
        aspect-ratio: 1;
        border-radius: 50%;
        transition: left 100ms;
      }
    }
    .container.enabled {
      background-color: $background-color_3;
      .knob {
        left: 25px;
      }
    }
  }
}

$color_1: #00789e;
$color_2: #ffffff;
$color_3: #696969;
$background-color_1: #ffffff;
$background-color_2: #f3fbfd;
$background-color_3: #00789e;
$border-color_1: #696969;

.toggle-button {
  background-color: #ffffff;
  border: 1px solid #00789e;
  color: #00789e;
  cursor: pointer;
  float: left;
  width: 50%;
  font-size: 0.9375rem;
  line-height: 1.6;
  letter-spacing: 0.4px;
  transition: font 0.15s ease-in-out;
  margin: 0;
  padding: 7px;
  background-color: #f3fbfd;
  &:nth-last-child(n):first-child,
  &:nth-last-child(n):first-child ~ * {
    width: 33.33%;
  }
  &:nth-last-child(n):first-child:first-child,
  &:nth-last-child(n):first-child ~ *:first-child {
    border-right: none;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }
  &:nth-last-child(n):first-child:last-child,
  &:nth-last-child(n):first-child ~ *:last-child {
    border-left: none;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    border-radius: 0 0 6px 0;
  }
  &:nth-last-child(n):first-child:nth-of-type(1),
  &:nth-last-child(n):first-child ~ *:nth-of-type(1) {
    border-radius: 6px 0 0 0;
  }
  &:nth-last-child(n):first-child:nth-of-type(2),
  &:nth-last-child(n):first-child ~ *:nth-of-type(2) {
    border-radius: 0 6px 0 0;
  }
  &:nth-last-child(n):first-child:nth-of-type(even),
  &:nth-last-child(n):first-child ~ *:nth-of-type(even) {
    border-left: none;
  }
  &:nth-last-child(n):first-child:nth-child(n + 3),
  &:nth-last-child(n):first-child ~ *:nth-child(n + 3) {
    border-top: none;
  }
  &:nth-last-child(n):first-child:nth-last-child(2),
  &:nth-last-child(n):first-child ~ *:nth-last-child(2) {
    border-radius: 0 0 0 6px;
  }
  &-row {
    display: block;
    margin-bottom: 16px;
    content: "";
    clear: both;
    display: block;
    &-no-margin {
      display: block;
      margin-bottom: 0;
    }
  }
}
.active-button {
  background-color: #00789e;
  border: 1px solid #00789e;
  color: #ffffff;
}
.disabled-button {
  background-color: #ffffff;
  border-color: #696969;
  color: #696969;
  cursor: not-allowed;
  opacity: 0.4;
}
media {
  font-size: 1rem;
}
