.text-title {
  font-size: 1.25rem;
  line-height: 1.2;
  letter-spacing: 1px;
  transition: font 0.15s ease-in-out;
}
@media only screen and (min-width: 692px) {
  .text-title {
    font-size: 1.6875rem;
  }
}
