@use "@angular/material" as mat;
@import "@angular/material/theming";
@import "./colors";

$dark-palette: (
  50: #e1f5f9,
  100: #b4e5f1,
  200: #85d3e8,
  300: #5ac2e0,
  400: #3cb5dc,
  500: $color-teal-dark-x,
  600: $color-png-blue-dark,
  700: #0c88b8,
  800: #0b77a5,
  900: $color-blue,
  A100: color(blue, base),
  A200: $color-teal-dark-x,
  A300: color(blue, base),
  A400: color(blue, base),
  contrast: (
    50: $black-87-opacity,
    100: $black-87-opacity,
    200: $black-87-opacity,
    300: $black-87-opacity,
    400: $black-87-opacity,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: $black-87-opacity,
    A200: $black-87-opacity,
    A400: $black-87-opacity,
    A700: white,
  ),
);

$dark-theme-primary: mat.define-palette($dark-palette);
$dark-theme-accent: mat.define-palette($dark-palette, A200, A100, A400);
$dark-theme: mat.define-dark-theme(
  (
    color: (
      primary: $dark-theme-primary,
      accent: $dark-theme-accent,
    ),
  )
);

$dark-background: #121212;
$dark-section-background: #1e1e1e;
$dark-header-footer-background: #1f2a2d;
$dark-highlight: #183038;
// Replaces #00789e
$dark-accent: #3fb7d3;
$dark-text: rgba(#ffffff, 70%);
$dark-button: #2f2f2f;
$dark-text-blue: #22a9d8;
$dark-body-text: #b8b8b8;

// region Tailwind dark mode based on "Dark Mode Cheat Sheet" designs
.bg {
  &-blue {
    @apply dark:bg-dm-blue;
  }
  &-gray {
    &-lighter {
      @apply dark:bg-dm-gray-lighter;
    }
  }
  &-green {
    @apply dark:bg-dm-green;
  }
  &-green-dark {
    @apply dark:bg-dm-green-dark;
  }
  &-orange {
    @apply dark:bg-dm-orange;
  }
  &-red {
    @apply dark:bg-dm-red;
  }
  &-teal-darker {
    @apply dark:bg-dm-accent;
  }
  &-white {
    @apply dark:bg-dm-sec-bg;
  }
  &-yellow {
    @apply dark:bg-dm-yellow;
  }
}

.text {
  &-blue {
    @apply dark:text-dm-blue;
  }
  &-gray {
    &-dark {
      @apply dark:text-dm-body-text;
    }
    &-darker {
      @apply dark:text-white;
    }
    &-lighter {
      @apply dark:text-dm-gray-lighter;
    }
  }
  &-green {
    @apply dark:text-dm-green;
  }
  &-green-dark {
    @apply dark:text-dm-green-dark;
  }
  &-orange {
    @apply dark:text-dm-orange;
  }
  &-red {
    @apply dark:text-dm-red;
  }
  &-teal-darker {
    @apply dark:text-dm-accent;
  }
  &-white {
    @apply dark:text-dm-sec-bg;
  }
  &-yellow {
    @apply dark:text-dm-yellow;
  }
}
// endregion

.dark-mode {
  @include mat.all-component-colors($dark-theme);
  color: $dark-text;

  a:not(.nav-group__button) {
    color: $dark-accent;
  }

  color-scheme: dark;

  // Duke Energy Overrides
  .de-site-header {
    $active: ".de-is-active";
    $current: ".de-is-current";
    &-main {
      background-color: $dark-header-footer-background !important;
    }
    &-title__link {
      color: white !important;
    }
    &-nav {
      .de-is-current .de-site-header-nav__link,
      .de-is-active .de-site-header-nav__link {
      }

      &__item {
        &.de-is-active {
          background-color: $dark-section-background !important;
        }
        &::after {
          background-color: $dark-section-background !important;
        }

        &::before {
          background-color: $dark-accent !important;
        }
      }

      &__link {
        &.main-link {
          color: white !important;
        }
        &.main-button {
          color: #22a9d8 !important;
        }
        &.submain-link {
          color: $dark-text-blue;
        }

        .de-is-current .de-is-active {
          background-color: $dark-highlight;
        }

        #{$current} &,
        #{$active} & {
          background-color: $dark-section-background !important;
        }

        &--arrow {
          background-color: inherit !important;
        }
      }

      &__child {
        &-group,
        &-link {
          background-color: $dark-section-background !important;
          &.submain-link {
            color: $dark-text-blue;
          }
        }

        &-item {
          border-color: $dark-accent !important;
        }
      }
    }
  }

  .de-site-header-nav__child-link .de-footer {
    background-color: $dark-header-footer-background !important;
  }

  // Side/mobile menu on smaller screen sizes
  .de-drawer {
    &__content {
      background-color: $dark-background !important;
    }
    &-greeting {
      background-color: $dark-header-footer-background !important;
    }
    .nav__group__section {
      background-color: $dark-header-footer-background;

      &-item:hover {
        background-color: $dark-highlight !important;
      }

      &:hover {
        background-color: $dark-highlight !important;
      }

      &.de-is-current {
        background-color: $dark-highlight;
      }
    }
  }

  .de-footer {
    background-color: $dark-header-footer-background !important;
  }

  .de-form-checkbox {
    $this: &;

    &__input {
      &:focus {
        background: $dark-section-background !important;
      }
    }

    &__label {
      background: inherit !important;

      &::before {
        background: $dark-section-background !important;
      }

      > span {
        &::before {
          box-shadow: 0 0px 0 2px $dark-section-background !important;
        }
      }
    }
  }

  // HTML Tag Overrides
  section:not(#dashboard-header) {
    background-color: $dark-section-background;
  }

  #dashboard-header {
    .multi-selector {
      background-color: $dark-section-background;
    }
  }

  // Angular Overrides
  .mat-mdc-cell {
    background-color: $dark-section-background;
    color: $dark-text;
  }

  .mat-mdc-header-cell {
    background-color: $dark-section-background;
    color: $dark-accent;
  }

  // Component Overrides
  app-de-icon {
    .icon {
      background-color: $dark-accent;
    }
  }

  app-icon-button {
    .de-button {
      background-color: $dark-section-background;
      color: $dark-accent;
    }
  }

  app-de-chip {
    .chip-container {
      background-color: $dark-button;
      color: white;
    }
  }

  app-data-refresh {
    .refresh-button {
      filter: invert(0.5);
    }
  }

  // Page Overrides
  app-dashboard {
    .title {
      color: white;
    }

    //.dashboard-header,
    .chart-container,
    .sites-summary-table {
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.5);
    }

    app-dashboard-header {
      .selected-item {
        color: white;
      }
      .dashboard-header__datepicker {
        color: white;
        background-color: $dark-section-background;
      }
    }

    app-jurisdiction-selector,
    app-multi-select-modal {
      .jurisdiction-selector {
        background-color: $dark-background !important;
        box-shadow: 0 0 6px 0 #4c4c4c !important;

        &__item {
          &:not(:last-child) {
            border-color: #4c4c4c !important;
          }

          &:hover {
            background-color: $dark-section-background !important;
          }
        }
      }
    }

    app-month-selector {
      form {
        background-color: $dark-background !important;
      }
    }

    app-energy-production-chart,
    app-line-chart-solar {
      div.button-group {
        .button {
          background-color: $dark-button;
          border: none;
          color: white;
        }
      }

      .legend-item,
      .legend-title {
        color: white;
      }
    }

    app-table-with-filters {
      .mat-mdc-table {
        thead {
          .column-headers {
            th {
              border-color: #4c4c4c !important;
            }
          }

          .total-header-row {
            th {
              border-color: #4c4c4c !important;
              background-color: $dark-button;
              color: $dark-text;

              &:first-child {
                border-color: #4c4c4c !important;
              }
            }
          }
        }

        tbody {
          .mat-column-siteName {
            border-color: #4c4c4c !important;
          }
        }
      }
    }
  }

  /* region Maps-specific */
  app-site-map {
    .map__zoom-in,
    .map__zoom-out,
    .maps-panel {
      background-color: $dark-section-background !important;
    }
    .map__cloudcoverage {
      button {
        background-color: $dark-section-background !important;
      }
    }
  }

  app-map-panel {
    .maps-panel {
      background-color: $dark-section-background !important;
      strong {
        color: white !important;
      }
      p {
        color: $dark-body-text !important;
      }

      &-title {
        color: $dark-accent !important;
      }

      .href-button {
        color: $dark-accent !important;
        text-decoration-color: $dark-accent !important;
      }

      .de-button {
        color: black;
      }
    }
  }

  app-search .search-bar-input {
    background-color: $dark-section-background !important;
    color: white !important;
  }
  /* endregion Maps-Specific */

  app-toggle {
    color: white;
  }

  app-toggle-button {
    button.toggled {
      img {
        filter: brightness(0) saturate(100%) invert(72%) sepia(41%)
          saturate(754%) hue-rotate(153deg) brightness(84%) contrast(96%) !important;
      }
    }

    button.untoggled {
      img {
        filter: brightness(0) saturate(100%) invert(71%) sepia(0%) saturate(6%)
          hue-rotate(139deg) brightness(109%) contrast(97%) !important;
      }
    }
  }

  app-chip {
    img {
      filter: brightness(0) saturate(100%) invert(72%) sepia(41%) saturate(754%)
        hue-rotate(153deg) brightness(84%) contrast(96%) !important;
    }
  }

  app-site-info-card {
    .flag-icon--flagged {
      filter: brightness(0) saturate(100%) invert(72%) sepia(41%) saturate(754%)
        hue-rotate(153deg) brightness(84%) contrast(96%) !important;
    }

    .flag-icon--unflagged {
      filter: brightness(0) saturate(100%) invert(71%) sepia(0%) saturate(6%)
        hue-rotate(139deg) brightness(109%) contrast(97%) !important;
    }
  }
}

// Utility class for elements intended for fullscreen by applying Element.requestFullscreen
// Usage: [ngClass]="themeService.themeBackgroundClass"
.dark-background::backdrop {
  background: $dark-section-background;
}

.chart-container.dark-background:fullscreen {
  background: $dark-section-background;
}
