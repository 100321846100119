de-button {
  .de-button-- {
    .icon {
      background-color: #fff;
    }
  }
  .de-button--secondary {
    .icon {
      background-color: #00789e;
    }
  }
}
a {
  &:focus-visible {
    outline: none;
  }
}
button {
  &:focus-visible {
    outline: none;
  }
}
div {
  &:focus-visible {
    outline: none;
  }
}
input {
  &:focus-visible {
    outline: none;
  }
}
select {
  &:focus-visible {
    outline: none;
  }
}
textarea {
  &:focus-visible {
    outline: none;
  }
}
