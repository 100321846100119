$color_1: #00789e;

.chart-container {
  padding: 24px;
  border-radius: 6px;
  .button-group {
    display: flex;
    column-gap: 16px;
    row-gap: 16px;
    padding-bottom: 16px;
    flex-wrap: wrap;
    justify-content: space-between;
    .button {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 6px;
      border: 1px solid #d7d9da;
      .value {
        font-size: 1.25rem;
        line-height: 1.2;
        letter-spacing: 1px;
        transition: font 0.15s ease-in-out;
        margin-left: 24px;
        color: $color_1;
      }
    }
    .button-export {
      padding: 0 11px;
    }
    .flex {
      display: flex;
    }
  }
  .canvas-container {
    height: 450px;
  }
  .flex-container {
    display: flex;
    justify-content: space-between;
    padding: 16px 54px 0;
    font-size: 0.6875rem;
    letter-spacing: 0.16px;
    transition: font 0.15s ease-in-out;
    line-height: 1;
    .legend-title {
      font-weight: 700;
    }
    .legend-item {
      position: relative;
      cursor: pointer;
      span {
        &:first-child {
          display: inline-block;
          height: 10px;
          width: 10px;
          margin-right: 7px;
        }
      }
    }
    .legend-item.toggled {
      &:before {
        content: " ";
        position: absolute;
        top: 45%;
        border-bottom: 1px solid #696969;
        width: 100%;
      }
    }
    .left {
      display: flex;
      column-gap: 16px;
    }
    .right {
      display: flex;
      column-gap: 16px;
    }
  }
}
.flex-reverse {
  flex-direction: row-reverse;
}
@media only screen and (min-width: 692px) {
  .chart-container {
    .button-group {
      .button {
        .value {
          font-size: 1.6875rem;
        }
      }
    }
    .flex-container {
      font-size: 0.75rem;
    }
  }
}
